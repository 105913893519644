var render = function render(){var _vm=this,_c=_vm._self._c;return _c('input',_vm._g(_vm._b({attrs:{"type":_vm.type},on:{"input":function($event){return _vm.$emit('update', $event.target.value)}}},'input',
    _vm.$attrs
    // https://vuejs.org/v2/guide/components-props.html#Disabling-Attribute-Inheritance
  ,false),
    _vm.$listeners
    // https://vuejs.org/v2/guide/components-custom-events.html#Binding-Native-Events-to-Components
  ))
}
var staticRenderFns = []

export { render, staticRenderFns }